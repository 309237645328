import { useEffect } from "react";
import { PRIMARY_COLOR } from "../../Constants/constants"
import Aos from "aos";
import 'aos/dist/aos.css';

export const About = () => {

    useEffect(() => {
        Aos.init({duration: 1500})
    }, []);

  return (
    <section className="about-section" id="about">
        <div className="d-flex flex-row justify-content-center align-items-center">
        <div className="filmstrip my-3" data-aos="fade-right">
            <div className="film-strip-content my-3 mx-md-5 my-md-4">
            <h2 className="about-heading w-auto d-inline-block  mb-4 text-light display-6">About <strong className="display-5" style={{fontFamily: "Pacifico", color:`${PRIMARY_COLOR}`}}>Clapin<span className="text-light display-5">.</span></strong></h2>
            <p className="about-para text-light px-lg-5 p-xl-none" style={{fontFamily: "'Montserrat', sans-serif"}}>
                <strong className="display-6" style={{fontFamily: "Pacifico", color:`${PRIMARY_COLOR}`}}>Clapin<span className="text-light">. </span></strong>
                is platform that allows us, the film makers around the world, to share our work, 
                connect with fellow film makers and find the perfect cast and crew for our film project.
                Cast and Crew for our next project is just a click away. 
            </p>
        </div>
        </div>
        </div>
    </section>
  )
}
