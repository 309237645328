import { GiFilmProjector, Gi3DGlasses } from "react-icons/gi"
import  { TbDrone } from "react-icons/tb"
import { BsFillCameraReelsFill, BsFillChatRightDotsFill, BsSoundwave, BsFillMicFill } from "react-icons/bs"
import { FaTicketAlt, FaUserAlt, FaPhotoVideo, FaFileAudio, FaClosedCaptioning } from "react-icons/fa"
import { ImVideoCamera } from "react-icons/im"
import { IoMdFilm } from "react-icons/io"
import { MdHeadphones } from "react-icons/md"
import { AiFillCamera } from "react-icons/ai"
import { RiComputerFill, RiProjector2Fill } from "react-icons/ri"
import { HiSpeakerphone } from "react-icons/hi"
import {SignUpForm} from "./signupform"
import { useState } from "react"
import styled from 'styled-components';


const Div = styled.div<{anim: boolean}>`
margin: auto;
position: absolute;
bottom: 8vh;
width: auto;
background-color: var(--PRIMARY_COLOR);
z-index: 1;
border-radius: 10px;

&::before{
  content: '';
  position: absolute;
  top: -20px;
  left: 0;
  height: 30px;
  width: 100%;
  background: repeating-linear-gradient(-135deg, #fff 0%, #fff 10%, #111 10%, #111 20%);
  transform: rotate(-20deg);
  transform-origin: bottom left;
  animation: ${props => props.anim ? "clap 1s ease forwards, movetop 1s" : "none"};
}
`

export const SignUp = () => {
  const [anim, setAnim] = useState(false)

  return (
    <section className="signup-section justify-content-center align-items-center" id="signup">
        <Div className='clapper d-flex align-items-center justify-content-center' onAnimationEnd={() => {setAnim(false)}} anim = {anim}>
          <div></div>
          <SignUpForm anim={anim} setAnim={setAnim}/>
      </Div>
      <AllIcons/>
    </section>
  )
}

const AllIcons = () => {
  return(
    <div className="icons-div">
    <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
      <IconsDiv/>
  </div>
  )
}

const IconsDiv = () =>{
  return(
    <div className="icons-row">
       <Icons/>
       <Icons/>
      </div>
  )
}

const Icons = () => {
  return(
          <div>
            <IoMdFilm className="icon" />
            <FaTicketAlt className="icon" />
            <ImVideoCamera className="icon" />
            <MdHeadphones className="icon" />
            <GiFilmProjector className="icon" />
            <TbDrone className="icon" />
            <BsFillCameraReelsFill className="icon" />
            <BsFillChatRightDotsFill className="icon" />
            <Gi3DGlasses className="icon" />
            <FaUserAlt className="icon" />
            <AiFillCamera className="icon" />
            <BsFillMicFill className="icon" />
            <RiComputerFill className="icon" />
            <RiProjector2Fill className="icon" />
            <FaClosedCaptioning className="icon" />
            <HiSpeakerphone className="icon" />
            <BsSoundwave className="icon" />
            <FaPhotoVideo className="icon" />
            <FaFileAudio className="icon" />
            <RiProjector2Fill className="icon" />
            <FaClosedCaptioning className="icon" />
            <HiSpeakerphone className="icon" />
            <BsSoundwave className="icon" />
            <FaPhotoVideo className="icon" />
            <FaFileAudio className="icon" />
            <IoMdFilm className="icon" />
            <FaTicketAlt className="icon" />
            <ImVideoCamera className="icon" />
            <MdHeadphones className="icon" />
            <GiFilmProjector className="icon" />
            <TbDrone className="icon" />
            <BsFillMicFill className="icon" />
            <RiComputerFill className="icon" />
            <RiProjector2Fill className="icon" />
            <FaClosedCaptioning className="icon" />
          </div>
  )
}
