import {FormikHelpers } from "formik";
import axios from 'axios'

interface FormValues {
    name: string;
    mail: string;
  }

export const submit = async (values: FormValues, formik: FormikHelpers<FormValues>, setAnim: React.Dispatch<React.SetStateAction<boolean>>) => {
    const { name, mail } = values;
    try {
      const payload = {
        name: name,
        email_address: mail,
      };

      await axios.post('/.netlify/functions/mail-collector', payload);
      setAnim(true)
      formik.resetForm();
    } catch (error: any) {
      console.error(error)
    }
  };