import { HeaderContent } from "./MainHeader"


export const Header = () => {
  return (
    <header>
    <HeaderContent/>
    </header>
  )
}
