import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Features } from './components/features/Features';
import { Header } from './components/header/Header';
import { Footer } from './components/Footer/Footer';
import { SignUp } from './components/singup/signup';
import { About } from './components/about/About';


function App() {
  return (
    <>
    {/* <FormExample/> */}
    <Header/>
    <About/>
    <Features/>
    <SignUp/>
    <Footer/>
    </>
  );
}

export default App;
