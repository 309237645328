import shake from "../../static/images/hand-shake.png"
import profile from "../../static/images/profile.png"
import share from "../../static/images/share.png"
import job from "../../static/images/job.png"
import Aos from "aos"
import 'aos/dist/aos.css';
import { useEffect } from "react"


export const Features = () => {
    
    useEffect(() => {
        Aos.init({duration: 1000})
    }, []);
    
  return (
    <section className="timeline-section" id="features">
    <div className="d-flex flex-column justify-content-space-around align-items-center fs-3" style={{fontFamily: "Poppins", fontWeight: 300}}>
        <div className="m-3">
        <strong className="display-5" style={{fontFamily: "Pacifico", color:"black"}}>Clapin<span className="text-light display-5">.</span></strong>
            <span className="text-light" style={{fontFamily: "Carter one"}}> allows us to</span>
        </div>
        </div>
	<div className="timeline-items container-fluid p-lg-5 py-5 pe-0">
		<div className="timeline-item mx-3 mx-lg-5">
			<div className="timeline-dot">
            <img src={profile} alt="" className="feature-icon-img" />
            </div>
			<div className="timeline-content" data-aos="fade-left">
            <h3>Create Profile</h3>
                    <p>You can create your profile to show fellow film users to have a look at your accomplishments
                        and your work.
                    </p>
			</div>
		</div>
		<div className="timeline-item mx-3 mx-lg-5">
			<div className="timeline-dot">
                <img src={shake} alt="" className="feature-icon-img" />
            </div>
			<div className="timeline-content" data-aos="fade-right">
            <h3>Make Connections</h3>
                    <p>You can connect with fellow film makers to see their work and directly message to them 
                    </p>
			</div>
		</div>
		<div className="timeline-item mx-3 mx-lg-5">
			<div className="timeline-dot">
            <img src={share} alt="" className="feature-icon-img" />
            </div>
			<div className="timeline-content" data-aos="fade-left">
            <h3>Share Work</h3>
                    <p>You can share your work in this platform to reach millions of fellow film makers in the world</p>
			</div>
		</div>
		<div className="timeline-item mx-3 mx-lg-5">
			<div className="timeline-dot">
            <img src={job} alt="" className="feature-icon-img" />
            </div>
			<div className="timeline-content" data-aos="fade-right">
            <h3>Search and Offer Work</h3>
                    <p>Clapin allows users to post any auditions to reach more nichely targetted talents across the globe</p>
			</div>
		</div>
	</div>
</section>
  )
}
