import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { submit } from "../../utils/submitForm";

interface FormValuesType {
  name: string;
  mail: string;
}

interface animValuesType {
  anim: boolean
  setAnim: React.Dispatch<React.SetStateAction<boolean>>
}

const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  mail: Yup.string().required("Required").email("Invalid Email!"),
});

export const SignUpForm = ({anim, setAnim}: animValuesType) => {

  const submitForm = async (values: FormValuesType, formik: FormikHelpers<FormValuesType>) => {
    submit(values, formik, setAnim)
  };

  return (
    <>
      <Formik
        initialValues={{ name: "", mail: ""}}
        validationSchema={SignupSchema}
        onSubmit={submitForm}
      >
        {(formik) => (
        <Form className="input-form p-4 p-md-5">
        <div className="container form-container d-flex flex-column justify-content-center align-items-center">
        <div className="input-div">
          <div className="my-input-container">
            <Field name="name" id="name" type="text" className="my-form-input input" placeholder='Name' autoComplete="off"/>
            <ErrorMessage
                component="div"
                className="error-message"
                name="name"
              />
        </div>
        <div className="my-input-container">
            <Field name="mail" type="text" id="mail" className="my-form-input input" placeholder='Email' autoComplete="off"/>
            <ErrorMessage
                component="div"
                className="error-message"
                name="mail"
              />
        </div>
        </div>
        {/* <div className="input-container form-outline">
            <input type="text" className="form-input" placeholder='Phone Number'/>
        </div> */}
        <div className='d-flex justify-content-center d-block'>
          <JoinCommunityButton/>
        </div>
        </div>
        </Form>
      )}
    </Formik>
    </>
  );
}

const JoinCommunityButton = () => {
  return(
    <button type="submit" className="p-2 join-button-signup-page"><strong>JOIN COMMUNITY</strong></button>
  )
}