
export const PRIMARY_COLOR: string = "#ffcc00"
export const DARK: string = "#292826"
// export const PRIMARY_COLOR: string = "#48E1CB"

// STRINGS

// NAVIGATION

export const HOME_LINK_TEXT = "Home"
export const FEATURES_LINK_TEXT = "Features"
export const JOIN_NOW_TEXT = "Join Now"
