import  HeaderImage  from "../../static/images/bg.jpg"
import TwitterIcon from "../../static/images/twitter.png"
import FacebookIcon from "../../static/images/facebook-Icon.png"
import InstagramIcon from "../../static/images/instagram-Icon.png"
import ArrowIcon from "../../static/images/arrow.png"
import { NavBar } from "./NavBar"
import { PRIMARY_COLOR } from "../../Constants/constants"

// rgba(23, 37, 42, 0.92)
export function HeaderContent() {
return(
    <div className="view h-auto position-relative" style={{
      backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)),url(${HeaderImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
    }}>
        <NavBar/>
      <div className="d-flex flex-row justify-content-center align-items-center">
        <div className="container-md m-md-5">
        <div className="row justify-content-center my-1 my-md-5">
          <div className="col-12 my-5 d-flex flex-column align-items-center text-center align-self-center">
            <h1 className="text-light my-4 w-100" style={{fontFamily: "Carter One"}}>
              <strong className="w-100 display-3 d-none d-md-inline-block">World's fastest growing <span className="changing-text" style={{color: `${PRIMARY_COLOR}`}}>film community</span>.</strong>
              <strong className="w-100 display-6 d-inline-block d-md-none">World's fastest growing <span className="changing-text" style={{color: `${PRIMARY_COLOR}`}}>film community</span>.</strong>
            </h1>
            <h3 className="text-light w-75 px-5 d-none d-lg-inline-block" style={{fontFamily:"Poppins", fontWeight: 300}}>
                <strong>A place where film enthusiasts come to express their thoughts, 
                        share their work and get connected with the film community around the world.
                </strong>
              </h3>
            <h3 className="text-light w-100 px-5 d-block d-lg-none" style={{fontFamily: "Poppins", fontWeight: 300}}>Join now to connect with the film community around the world.</h3>
          </div>
          <div className="col-12 text-center">
              <JoinCommunityButton/>
              </div>
        </div>
        <div className="text-center mt-4 p-2">
            <SocialMediaIcons/>
        </div>
        </div>
      </div>
    </div>
  )
}

const JoinCommunityButton = () => {
  return(
    <button type="button" className="p-2 join-button"><strong>JOIN COMMUNITY</strong></button>
  )
}

const SocialMediaIcons = () => {
  return(
    <div className="">
      <div className="mb-4">
              <a className="mx-2" href="www.facebook.com">
                <img src={FacebookIcon} style={{width: "45px", height: "45px"}} alt="facebook" />
              </a>
              <a className="mx-2" href="www.twitter.com">
                <img src={TwitterIcon} style={{width: "45px", height: "45px"}} alt="twitter" />
              </a>
              <a className="mx-2" href="www.instgram.com">
                <img src={InstagramIcon} style={{width: "45px", height: "45px"}} alt="instagram" />
              </a>
      </div>
      <div className="">
        <a href="#about">
                <img src={ArrowIcon} style={{width: "30px", height: "30px"}} alt="arrow" />
        </a>
      </div>
     </div>
  )
  
}

// text-center position-absolute bottom-0 start-50 translate-middle-x