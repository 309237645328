import {FaRegCopyright} from "react-icons/fa"

export const Footer = () => {
  return (
    <div className='footer d-flex justify-content-center align-items-center' style={{height:"12vh"}}>
        <div className="container">
        <div className="flex-lg-grow-1 text-center">
            <FaRegCopyright className="mb-3 mx-2" style={{color: "white"}} size={30}/>
            <strong className="display-5" style={{fontFamily: "Pacifico"}}>Clapin<span className="text-light display-5">.</span>co</strong>
          </div>
        </div>
    </div>
  )
}
