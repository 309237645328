import { PRIMARY_COLOR } from "../../Constants/constants"

export const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-md navbar-dark mx-4 mx-md-5">
        <div className="d-flex flex-wrap flex-row justify-content-space-around align-items-center w-100">
          <ComingSoon/>
          <CompanyName/>
          <HamBurger/>
          <Menu/>
    </div>
    </nav>
  )
}


const ComingSoon = () => {
  return(
    <div className="d-none d-lg-inline-block h3 text-light mt-2 border-coners p-2" style={{fontFamily: "Teko"}}>
            <span className="mx-2">LAUNCHING SOON</span>
     </div>
  )
}

const CompanyName = () => {
  return(
    <div className="flex-grow-1 d-inline-block text-lg-center mx-auto">
            <a className="navbar-brand text-light" href="www.clapin.co">
              <strong className="display-5" style={{fontFamily: "Pacifico", color:`${PRIMARY_COLOR}`}}>Clapin<span className="text-light display-5">.</span></strong>
            </a>
    </div>
    
  )
}

const Menu = () => {
  return(
    <div className="justify-content-end flex-grow-0 collapse navbar-collapse text-end mt-3 mt-lg-0" id="navbar">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link text-light fw-bold" style={{fontFamily: "Raleway", fontSize: 18}} href="#about">
                  <span className="nav-item-span p-2">About</span> 
                </a>
              </li>
              <li className="nav-item">
              <a className="nav-link text-light fw-bold" style={{fontFamily: "Raleway", fontSize: 18}} href="#features">
                <span className="nav-item-span p-2">Features</span> 
                </a>
              </li>
              <li className="nav-item">
              <a className="nav-link text-light fw-bold" style={{fontFamily: "Raleway", fontSize: 18}} href="#signup">
                <span className="nav-item-span p-2">Join Now</span> 
                </a>
              </li>
            </ul>
          </div>
  )
}
//style={{backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9))"}}
// export const ResponsiveMenu = () => {
//   return(
//     <div className="d-flex vh-100 collapse navbar-collapse justify-content-center align-items-center text-center responsive-menu" 
//           id="navbar" 
//           style={{backgroundColor: "rgba(0, 0, 0, .9)"}}> 
//             <ul className="navbar-nav container">
//               <li className="nav-item">
//                 <a className="nav-link fw-bold" style={{fontFamily:"Kumar One Outline", fontSize: 50, color:`${PRIMARY_COLOR}`}} href="#">
//                   About 
//                 </a>
//               </li>
//               <li className="nav-item">
//               <a className="nav-link text-light fw-bold" style={{fontFamily:"Kumar One Outline", fontSize: 50, color:`${PRIMARY_COLOR}`}} href="#">
//                   Features
//                 </a>
//               </li>
//               <li className="nav-item">
//               <a className="nav-link text-light fw-bold" style={{fontFamily:"Kumar One Outline", fontSize: 50, color:`${PRIMARY_COLOR}`}} href="#">
//                   Contact
//                 </a>
//               </li>
//             </ul>
//           </div>
//   )
// }

const HamBurger = () => {
  return(
    <button className="d-flex flex-column align-items-end navbar-toggler collapsed d-inline-block d-md-none" 
                  type="button" 
                  data-bs-toggle="collapse" 
                  data-bs-target="#navbar" 
                  aria-controls="navbar" 
                  aria-expanded="false" 
                  aria-label="Toggle navigation"
                  style={{color: `${PRIMARY_COLOR}`}}>
            <div className="toggler-icon top-bar"></div>
            <div className="toggler-icon middle-bar"></div> 
            <div className="toggler-icon bottom-bar"></div>           
            </button>
  )
}
//<BiMenuAltRight size={40}/>